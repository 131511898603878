import React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import Address from "../components/address"
import List from "../components/list"
import Aside from "../components/aside"
import Item from "../components/item"
import Top from "../components/top"
import SEO from "../components/seo"
import Pagination from "../components/pagination"

export default ({pageContext: {chuck, nowPage, page, classId, className, randData}}) => (
    <Layout>
        <SEO title={className} />
        <Header nav={`class_${classId}`} />
        <div style={{
                display: `flex`,
                justifyContent: `space-around`,
                maxWidth: `960px`,
                margin: `1rem auto`
        }}>
            <List storys={chuck}
            Pagination={<Pagination nowPage={nowPage} countPage={page} classIndex={classId} />}
            >
                <Address classUrl={`/class/${classId}/`} className={className} />
            </List>
            <Aside>
                <Top />
                <Item obj={{
                    class_name: "谁便看看",
                    data: randData
                }} />
            </Aside>
        </div>
    </Layout>
)
